@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=SUSE:wght@100..800&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  /* background: linear-gradient(180deg, #000, #000), url(); */
}
body {
  background: #ecf0f3;
}
.fontSuse {
  font-family: "SUSE", sans-serif !important;
}
.navbar .active{
  color: #faa21b;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #082635;
  border: 3px solid white;
  border-radius: 30px;
}

.filter-white {
  filter: invert(100%) sepia(28%) saturate(0%) hue-rotate(305deg)
    brightness(116%) contrast(101%);
}
.filter-orange {
  filter: invert(62%) sepia(92%) saturate(841%) hue-rotate(347deg)
    brightness(101%) contrast(96%);
}

.swiper {
  width: 100%;
  padding: 60px 0 !important;
}
.coursesSwiper {
  padding: 60px 0 !important;
}
.teamSwiper {
  padding: 100px 0 140px !important;
}
@media screen and (max-width: 600px) {
  .teamSwiper {
    padding: 70px 0 120px !important;
  }
  .coursesSwiper {
    padding: 40px 0 60px !important;
  }
}
.swiper-pagination-bullet {
  background-color: #fff !important;
  width: 15px !important;
  height: 15px !important;
}
.swiper-pagination-bullet-active {
  background-color: #faa21b !important;
}
.coursesSwiper .swiper-pagination-bullet {
  background-color: #faa21b !important;
  width: 15px !important;
  height: 8px !important;
  border-radius: 30px !important;
}
.coursesSwiper .swiper-pagination-bullet-active {
  width: 25px !important;
}
.swiper-button-prev,
.swiper-button-next,
.swiper-button-next-course,
.swiper-button-prev-course {
  bottom: 0 !important;
  top: unset !important;
  border: 1px solid #082635;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  padding: 6px !important;
  color: #082635 !important;
  position: absolute !important;
  z-index: 1 !important;
}
.swiper-button-prev,
.swiper-button-prev-course {
  right: 90px !important;
  left: unset !important;
}
.swiper-button-next,
.swiper-button-next-course {
  right: 40px !important;
}
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.floating {
  animation: float 3s ease-in-out infinite;
}

.textStroke {
  -webkit-text-stroke: 1px #08263575;
}
.zx{
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
}
.custom-shape-divider-bottom-1725395112 {
  min-width: 1200px;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1725395112 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 92px;
}

.custom-shape-divider-bottom-1725395112 .shape-fill {
  fill: #082635;
}
